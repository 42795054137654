header{
    background-repeat: no-repeat;
    position: relative;
    width: 100vw;
    display: grid;
  }
  
  .title-group{
    display: grid;
    place-content: center;
  }
  
  .title{
    text-align: center;
    color: #ffeeb3;
  }