.tablink {
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
    padding: 14px 16px;
    font-size: 17px;
  }

  .tablink:hover {
    background-color: #ffeeb3;
    color: #817f75;
    transition: ease-in-out;
    transition-duration: .75s;
  }

  .tablink-active{
    border-bottom: 5px solid #817f75;
  }

  .tabcontent {
    background-color: #f5f5f5;
    min-height: 300px;
    padding: 25px 20px;
  }
