/* full navbar */
  .nav-links, .navbar{
    width: 100%;
    justify-content: space-around;
  }
  
  .navbar{
    display: flex;
  }
  
  .nav-links{
    align-items: flex-end;
  }
  
  .nav-links li{
    padding: 5px;
    list-style-type: none;
    border-radius: 4%;
  }
  
  .nav-links li a:hover{
    background: #817f75;
    transition: ease-in-out .5s;
    color: #ffeeb3;
  }

  .nav-links li a{
    color: #f1c17a;
    font-size: 1.25em;
    text-align: center;
    display: grid;
    justify-content: center;
    padding: 12px;
  }
  
  /* hamburger button */
  ul .hamburger-item a{
    color: #f1c17a;
    text-decoration: none;
    font-size: 18px;
    padding: 15px;
    display: block;
    height: 100%;
  }

  #hamburger-container label:hover, .hamburger-item:hover, ul .hamburger-item a:hover{
    background-color: #817f75 !important;
    color: #ffeeb3;
  }
  
  #hamburger + ul .hamburger-item{
    display: none;
  }
  
  #hamburger, #hamburger-container label{
    position: absolute;
    color: #f1c17a;
    right: 0;
    z-index: 997;
  }
  
  #hamburger-container label{
    top: 0;
    font-size: 2rem;
    width: 100vw;
    text-align: center;
    background-color: #373F47;
  }
  
  #hamburger-container label{
    padding-bottom: 4px;
  }
  
  #hamburger-container label span{
    padding: 0 1rem 0 1rem;
  }

  #hamburger{
    opacity: 0;
  }
  
  #hamburger:checked + ul .hamburger-item{
    display: block;
    text-align: left;
    position: relative;
    z-index: 997;
    width: 100vw;
  }
  
  #hamburger-background{
    position: absolute;
    right: 0;
    top: 45px;
    background-color: #373F47;
  }
  
  .top-navbar-item-list:hover .dropdown-content{
    display: block;
  }
  
  .dropdown-content{
    display: none;
    position: absolute;
    z-index: 999;
  }
  
  .dropdown-content a{
    display: block;
    position: relative;
    text-align: left;
    text-decoration: none;
    padding: 8px 15px;
    z-index: 998;
  }